<template>
  <div class="myScheduleReminderSettingsCalendar">
    <base-heading
      type="2"
      modifiers="center"
      class="myScheduleReminderSettingsCalendar__title"
    >
      {{ $t('myScheduleReminderSettingsModal.stepCalendarTitle') }}
    </base-heading>

    <p class="myScheduleReminderSettingsCalendar__body">
      {{ $t('myScheduleReminderSettingsModal.stepCalendarBody') }}
    </p>

    <ol class="myScheduleReminderSettingsCalendar__list">
      <li class="myScheduleReminderSettingsCalendar__listItem">
        {{ $t('myScheduleReminderSettingsModal.stepCalendarListItemStep1') }}
      </li>

      <li class="myScheduleReminderSettingsCalendar__listItem">
        {{ $t('myScheduleReminderSettingsModal.stepCalendarListItemStep2') }}
      </li>

      <li class="myScheduleReminderSettingsCalendar__listItem">
        {{ $t('myScheduleReminderSettingsModal.stepCalendarListItemStep3') }}
      </li>
    </ol>

    <base-button
      href="#"
      modifiers="primary block fullWidth"
      ref="downloadCalendar"
      v-on:click="onDownloadClick"
    >
      {{ $t('myScheduleReminderSettingsModal.stepCalendarDownloadButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import createMyScheduleICSFile from '@/utils/createMyScheduleICSFile';
import mapMyScheduleToICSEvents from '@/utils/mapMyScheduleToICSEvents';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    BaseHeading,
    BaseButton,
  },

  data() {
    return {
      icsFile: null,
    };
  },

  computed: {
    ...mapState('schedule', ['schedule']),
    ...mapGetters('program', ['activePrograms']),
  },

  mounted() {
    const fileName = 'weekplanner.ics';
    const link = this.$refs.downloadCalendar.$el;
    const events = mapMyScheduleToICSEvents(this.schedule, this.activePrograms);
    const file = createMyScheduleICSFile(fileName, events);

    // if we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks
    if (this.icsFile !== null) {
      window.URL.revokeObjectURL(this.icsFile);
    }

    this.icsFile = window.URL.createObjectURL(file);

    link.download = fileName;
    link.href = this.icsFile;
  },

  methods: {
    onDownloadClick() {
      this.trackEvent('Weekplanner iCal downloaded', {
        Days: this.schedule.length,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleReminderSettingsCalendar__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.myScheduleReminderSettingsCalendar__body {
  margin: 0 0 3rem 0;
  text-align: center;
  color: $color-grey;
}

.myScheduleReminderSettingsCalendar__list {
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;
  counter-reset: counter;
}

.myScheduleReminderSettingsCalendar__listItem {
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem 0;
  counter-increment: counter;

  &:last-child {
    margin: 0;
  }

  &:before {
    content: counter(counter);
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 0 0;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    font-weight: bold;
    color: $color-green;
    background-color: $color-beige;
  }
}
</style>