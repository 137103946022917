<template>
  <div
    v-bind:class="{
      myScheduleSettingsItemHeader: true,
      'myScheduleSettingsItemHeader--active': isActive
    }"
  >
    <div
      class="myScheduleSettingsItemHeader__label"
      v-on:click="$emit('select')"
    >
      <div
        v-bind:class="{
          myScheduleSettingsItemHeader__radioButton: true,
          'myScheduleSettingsItemHeader__radioButton--completed': isCompleted
        }"
      >
        <font-awesome-icon
          class="myScheduleSettingsItemHeader__radioButton__icon"
          v-bind:icon="['fas', 'check']"
        />
      </div>

      <div class="myScheduleSettingsItemHeader__defaultLabel">
        {{ label }}
      </div>

      <div
        v-if="completedLabel"
        class="myScheduleSettingsItemHeader__completedLabel"
      >
        {{ completedLabel }}
      </div>
    </div>

    <base-button
      v-if="isActive"
      modifiers="secondary xs"
      class="myScheduleSettingsItemHeader__deleteButton"
      v-on:click="$emit('delete')"
    >
      <font-awesome-icon
        class="myScheduleSettingsItemHeader__deleteButton__icon"
        v-bind:icon="['fas', 'minus']"
      />

      {{ $t('myScheduleSettingsModal.step2RemoveDayButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import moment from 'moment';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    selectedDay: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    isCompleted: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    completedLabel() {
      if (this.selectedDay.date) {
        const { date } = this.selectedDay;
        const weekDay = moment(date).format('dddd');
        const startTime = moment(date).format('HH:mm');

        if (!this.isActive && weekDay && startTime) {
          return `${weekDay} ${startTime}`;
        }
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleSettingsItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.125rem;
}

.myScheduleSettingsItemHeader__label {
  display: flex;
  align-items: center;

  .myScheduleSettingsItemHeader:not(.myScheduleSettingsItemHeader--active) & {
    cursor: pointer;
  }
}

.myScheduleSettingsItemHeader__radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.75rem 0 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid $color-beige--dark;

  &--completed {
    background: $color-green;
    border-color: $color-green;
  }
}

.myScheduleSettingsItemHeader__radioButton__icon {
  display: none;

  .myScheduleSettingsItemHeader__radioButton--completed & {
    display: block;
    color: $color-white;
  }
}

.myScheduleSettingsItemHeader__defaultLabel {
  @include lead;
}

.myScheduleSettingsItemHeader__completedLabel {
  @include label;
  margin: 0 0 0 0.5rem;
}

.myScheduleSettingsItemHeader__deleteButton {
  width: auto;
}

.myScheduleSettingsItemHeader__deleteButton__icon {
  margin: 0 0.5rem 0 0;
}
</style>
