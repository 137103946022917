<template>
  <div class="myScheduleSettingsItem">
    <div
      v-show="!isCollapsed"
      class="myScheduleSettingsItem__form"
    >
      <div v-if="isBeforeToday">
        {{
          $t('myScheduleSettingsModal.step2PastWorkoutNote', {
            pastWorkoutDate,
          })
        }}
      </div>

      <div v-if="!isBeforeToday">
        <div class="myScheduleSettingsItem__formElement">
          <label class="myScheduleSettingsItem__label">
            {{ $t('myScheduleSettingsModal.step2SelectDayLabel') }}
          </label>

          <Selectbox
            v-model="selectedDate"
            class="myScheduleSettingsItem__select"
            v-bind:options="weekDays"
            v-bind:selected="selectedDate"
          />
        </div>

        <div class="myScheduleSettingsItem__formElement">
          <label class="myScheduleSettingsItem__label">
            {{ $t('myScheduleSettingsModal.step2SelectTimeLabel') }}
          </label>

          <Selectbox
            v-model="selectedStartTime"
            class="myScheduleSettingsItem__select"
            v-bind:options="timeSlots"
            v-bind:selected="selectedStartTime"
          />
        </div>

        <div class="myScheduleSettingsItem__formElement">
          <button
            v-if="!showProgramSelection && activePrograms.length"
            class="myScheduleSettingsItem__showProgramsButton"
            v-on:click="showProgramSelection = true"
          >
            <font-awesome-icon
              class="myScheduleSettingsItem__showProgramsButton__icon"
              icon="person-running"
            />

            {{ $t('myScheduleSettingsModal.step2SelectProgramTitle') }}
          </button>

          <div v-show="showProgramSelection">
            <label class="myScheduleSettingsItem__label">
              {{ $t('myScheduleSettingsModal.step2SelectProgramLabel') }}
            </label>

            <Selectbox
              v-model="selectedProgram"
              class="myScheduleSettingsItem__select"
              v-bind:options="programs"
              v-bind:selected="selectedProgram"
            />
          </div>

          <p
            v-if="activePrograms.length"
            class="myScheduleSettingsItem__note"
          >
            {{ $t('myScheduleSettingsModal.step2SelectProgramNote') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Selectbox from '@/components/Selectbox';
import generateTimeSlots from '@/utils/generateTimeSlots';
import getFirstMomentOfThisPeriod from '@/utils/getFirstMomentOfThisPeriod';

export default {
  components: {
    Selectbox,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    isCollapsed: {
      type: Boolean,
      required: true,
    },

    activePrograms: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      selectedDate: this.getDate(),
      selectedStartTime: this.getStartTime(),
      selectedProgram: this.modelValue.programId || null,
      showProgramSelection: false,
    };
  },

  watch: {
    selectedDate() {
      this.onChange();
    },

    selectedStartTime() {
      this.onChange();
    },

    selectedProgram() {
      this.onChange();
    },
  },

  computed: {
    isBeforeToday() {
      return moment(this.modelValue.date).isBefore(moment(), 'day');
    },

    pastWorkoutDate() {
      return moment(this.modelValue.date).format('dddd');
    },

    weekDays() {
      const weekDayOptions = new Array(7)
        .fill(0)
        .map((value, index) => {
          const date = getFirstMomentOfThisPeriod('week').add(index, 'days');
          const isDisabled = date.isBefore(moment().subtract(1, 'day'));

          return {
            name: date.format('dddd'),
            value: date.format('YYYY-MM-DD'),
            disabled: isDisabled,
          };
      });

      return [
        {
          name: this.$t('myScheduleSettingsModal.step2SelectDayPlaceholder'),
          value: null,
          disabled: true,
        },
        ...weekDayOptions,
      ];
    },

    timeSlots() {
      const isToday = !!this.selectedDate && moment(this.selectedDate).isSame(moment(), 'day');
      const timeSlots = generateTimeSlots(15).map(timeSlot => {
        const selectedDate = this.selectedDate || moment();
        const dateTime = moment(`${selectedDate} ${timeSlot}`);
        const isDisabled = isToday && dateTime.isBefore(moment());

        return {
          name: timeSlot,
          value: timeSlot,
          disabled: isDisabled,
        }
      });

      return [
        {
          name: this.$t('myScheduleSettingsModal.step2SelectTimePlaceholder'),
          value: null,
          disabled: true,
        },
        ...timeSlots,
      ];
    },

    programs() {
      return [
        {
          name: this.$t('myScheduleSettingsModal.step2SelectProgramPlaceholder'),
          value: null,
          disabled: true,
        },
        ...this.activePrograms.map(({ trainers, name, id }) => ({
          name: this.$t('myScheduleSettingsModal.step2SelectProgramItem', {
            program: name,
            trainer: trainers[0].name,
          }),
          value: id,
        }))
      ];
    },
  },

  methods: {
    getDate() {
      if (this.modelValue.date) {
        return moment(this.modelValue.date).format('YYYY-MM-DD');
      }

      return null;
    },

    getStartTime() {
      if (this.modelValue.date) {
        return moment(this.modelValue.date).format('HH:mm');
      }

      return null;
    },

    onChange() {
      if (!this.selectedDate || !this.selectedStartTime) {
        return;
      }

      const [ hours, minutes ] = this.selectedStartTime.split(':');
      const date = moment(this.selectedDate)
        .add(Number(hours), 'hours')
        .add(Number(minutes), 'minutes')
        .format('YYYY-MM-DDTHH:mm');

      this.$emit('update:modelValue', {
        programId: Number(this.selectedProgram) || null,
        date,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleSettingsItem__form {
  padding: 1rem 2.75rem;
}

.myScheduleSettingsItem__formElement {
  margin: 0 0 1.25rem 0;

  &:last-child {
    margin: 0;
  }
}

.myScheduleSettingsItem__label {
  margin: 0 0 0.25rem 0;
}

.myScheduleSettingsItem__select {
  display: block;
  margin: 0.25rem 0 0;
  max-width: 200px;
  width: auto;
}

.myScheduleSettingsItem__note {
  @include note;
  margin: 0.25rem 0 0 0;
}

.myScheduleSettingsItem__showProgramsButton {
  @include label--bold;
  color: $color-green;
}

.myScheduleSettingsItem__showProgramsButton__icon {
  margin: 0 0.25rem 0 0;
  color: $color-green;
}
</style>
