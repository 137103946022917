<template>
  <div class="myScheduleSettingsStep2">
    <base-heading
      type="2"
      modifiers="center"
      class="myScheduleSettingsStep2__title"
    >
      {{ $t('myScheduleSettingsModal.step2Title') }}
    </base-heading>

    <p class="myScheduleSettingsStep2__body">
      {{ $t('myScheduleSettingsModal.step2Body') }}
    </p>

    <ul class="myScheduleSettingsStep2__days">
      <li
        v-for="(day, index) in selectedDays"
        v-bind:key="index"
        class="myScheduleSettingsStep2__day"
      >
        <my-schedule-settings-item-header
          v-bind:is-active="index === currentIndex"
          v-bind:is-completed="!!selectedDays[index].date"
          v-bind:label="`Dag ${index + 1}`"
          v-bind:selected-day="selectedDays[index]"
          v-on:select="onSelectDay(index)"
          v-on:delete="onDeleteDay(index)"
        />

        <my-schedule-settings-item
          v-bind:is-collapsed="index !== currentIndex"
          v-bind:active-programs="activePrograms"
          v-model="selectedDays[index]"
        />
      </li>
    </ul>

    <base-button
      modifiers="secondary xs"
      class="myScheduleSettingsStep2__addDayButton"
      v-bind:disabled="selectedDays.length >= 7"
      v-on:click="onAddDay"
    >
      <font-awesome-icon
        class="myScheduleSettingsStep2__addDayButton__icon"
        v-bind:icon="['fas', 'plus']"
      />

      {{ $t('myScheduleSettingsModal.step2AddDayButtonLabel') }}
    </base-button>

    <div class="myScheduleSettingsStep2__buttonWrapper">
      <base-button
        modifiers="primary block fullWidth"
        v-bind:disabled="!isSubmitEnabled"
        v-bind:show-spinner="isSaving"
        v-on:click="onSave"
      >
        {{ $t('myScheduleSettingsModal.step2SaveButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MyScheduleSettingsItemHeader from '@/components/myschedule/MyScheduleSettingsItemHeader';
import MyScheduleSettingsItem from '@/components/myschedule/MyScheduleSettingsItem';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    MyScheduleSettingsItemHeader,
    MyScheduleSettingsItem,
    BaseHeading,
    BaseButton,
  },

  props: {
    modelValue: {
      type: Array,
      required: true,
    },

    numDaysSelected: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      currentIndex: 0,
      selectedDays: [],
      isSubmitEnabled: false,
      isSaving: false,
    };
  },

  computed: {
    ...mapGetters('program', ['activePrograms']),
  },

  watch: {
    selectedDays: {
      handler(newValue) {
        this.isSubmitEnabled = newValue.every(({ date }) => !!date);
      },
      deep: true,
    },
  },

  created() {
    if (this.modelValue.length) {
      this.selectedDays = [...this.modelValue];
      return;
    }

    this.selectedDays = new Array(this.numDaysSelected).fill({});
  },

  mounted() {
    this.trackEvent('Weekplanner scheduler started', {
      Step: 2,
      Days: this.selectedDays.length,
    });
  },

  methods: {
    onSelectDay(index) {
      this.currentIndex = index;
    },

    onAddDay() {
      if (this.selectedDays.length >= 7) {
        // ain't no more days in the week ;)
        return;
      }

      this.selectedDays.push({});
      this.currentIndex = this.selectedDays.length - 1;
    },

    onDeleteDay(index) {
      this.selectedDays.splice(index, 1);
    },

    onSave() {
      this.isSaving = true;
      this.$emit('update:modelValue', this.selectedDays);
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleSettingsStep2 {
  padding: 0 0 6rem 0;

  @include desktop {
    padding: 0;
  }
}

.myScheduleSettingsStep2__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.myScheduleSettingsStep2__body {
  margin: 0 0 2rem 0;
  text-align: center;
}

.myScheduleSettingsStep2__days {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
}

.myScheduleSettingsStep2__day {
  margin: 0 0 1rem 0;

  &:last-child {
    margin: 0;
  }
}

.myScheduleSettingsStep2__addDayButton {
  margin: 0 auto 2.5rem auto;
}

.myScheduleSettingsStep2__addDayButton__icon {
  margin: 0 0.5rem 0 0;
}

.myScheduleSettingsStep2__buttonWrapper {
  @include mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-top: 1px solid rgba(#000, 0.05);
    background: $color-white
  }
}
</style>
