<template>
  <div class="myScheduleSettingsStep1">
    <base-heading
      type="2"
      modifiers="center"
      class="myScheduleSettingsStep1__title"
    >
      {{ $t('myScheduleSettingsModal.step1Title') }}
    </base-heading>

    <p class="myScheduleSettingsStep1__body">
      {{ $t('myScheduleSettingsModal.step1Body') }}
    </p>

    <p class="myScheduleSettingsStep1__label">
      {{ $t('myScheduleSettingsModal.step1Label') }}
    </p>

    <ul class="myScheduleSettingsStep1__numDays">
      <li
        v-for="n in 7"
        v-bind:key="n"
        v-bind:class="{
          myScheduleSettingsStep1__numDaysItem: true,
          'myScheduleSettingsStep1__numDaysItem--active': numDaysSelected === n
        }"
        v-on:click="onNumDaysClick(n)"
      >
        {{ n }}
      </li>
    </ul>

    <p
      v-if="showBeginnerNotification"
      class="myScheduleSettingsStep1__starterNotice"
    >
      {{ $t('myScheduleSettingsModal.step1Note') }}
    </p>

    <div class="myScheduleSettingsStep1__buttonWrapper">
      <base-button
        modifiers="primary block fullWidth"
        v-bind:disabled="!numDaysSelected"
        v-on:click="onSubmitClick"
      >
        {{ $t('myScheduleSettingsModal.step1NextStepButtonLabel') }}
      </base-button>

      <base-button
        v-if="hasSchedule"
        v-bind:show-spinner="isStopping"
        modifiers="secondary block fullWidth"
        class="myScheduleSettingsStep1__stopButton"
        v-on:click="onStopClick"
      >
        {{ $t('myScheduleSettingsModal.step1StopButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    BaseHeading,
    BaseButton,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      numDaysSelected: this.modelValue,
      isStopping: false,
    };
  },

  computed: {
    ...mapGetters('schedule', ['hasSchedule']),

    showBeginnerNotification() {
      const isStarter = true;

      return isStarter && this.numDaysSelected > 2;
    },
  },

  mounted() {
    this.trackEvent('Weekplanner scheduler started', {
      Step: 1,
    });
  },

  methods: {
    onNumDaysClick(numDays) {
      this.numDaysSelected = numDays;
      this.$emit('update:modelValue', this.numDaysSelected);
    },

    onSubmitClick() {
      this.$emit('next');
    },

    onStopClick() {
      this.isStopping = true;
      this.$emit('stop');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleSettingsStep1 {
  padding: 0 0 6rem 0;

  @include desktop {
    padding: 0;
  }
}

.myScheduleSettingsStep1__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.myScheduleSettingsStep1__body {
  margin: 0 0 2rem 0;
  text-align: center;
}

.myScheduleSettingsStep1__label {
  margin: 0 0 1.5rem 0;
  text-align: center;
  color: $color-black;
}

.myScheduleSettingsStep1__numDays {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto 2.5rem auto;
  padding: 0;
  max-width: 18.75rem;
  list-style: none;
}

.myScheduleSettingsStep1__numDaysItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0.5rem 0.5rem 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid $color-beige--dark;
  color: $color-green;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    width: 3.25rem;
    height: 3.25rem;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: border 0.25s;
  }

  &--active,
  &:hover {
    box-shadow: 0px 8px 12px 0px rgba(#000, 0.06);

    &:after {
      border-color: $color-green;
    }
  }

  @include desktop {
    margin: 0 0.75rem 0.75rem 0;
  }

  &:nth-child(4),
  &:last-child {
    margin-right: 0;
  }
}

.myScheduleSettingsStep1__starterNotice {
  margin: 0 0 3.5rem 0;
  padding: 1.5rem;
  text-align: center;
  background: $color-beige;
  border-radius: $border-radius;
}

.myScheduleSettingsStep1__buttonWrapper {
  @include mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-top: 1px solid rgba(#000, 0.05);
    background: $color-white;
  }
}

.myScheduleSettingsStep1__stopButton {
  margin: 0.75rem 0 0 0;
}
</style>
