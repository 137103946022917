<template>
  <div class="myScheduleSettings">
    <my-schedule-settings-step-1
      v-if="currentStep === 1"
      v-model="numDaysSelected"
      v-on:next="currentStep = 2"
      v-on:stop="onStop"
    />

    <my-schedule-settings-step-2
      v-if="currentStep === 2"
      v-model="selectedDays"
      v-bind:num-days-selected="numDaysSelected"
      v-on:next="onSave"
    />

    <my-schedule-reminder-settings-index
      v-if="currentStep === 3"
      v-on:calendar="currentStep = 4"
      v-on:phone="currentStep = 5"
    />

    <my-schedule-reminder-settings-calendar
      v-if="currentStep === 4"
    />

    <my-schedule-reminder-settings-phone
      v-if="currentStep === 5"
      v-on:next="$emit('close')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MyScheduleSettingsStep1 from '@/components/myschedule/MyScheduleSettingsStep1';
import MyScheduleSettingsStep2 from '@/components/myschedule/MyScheduleSettingsStep2';
import MyScheduleReminderSettingsIndex from '@/components/myschedule/MyScheduleReminderSettingsIndex';
import MyScheduleReminderSettingsCalendar from '@/components/myschedule/MyScheduleReminderSettingsCalendar';
import MyScheduleReminderSettingsPhone from '@/components/myschedule/MyScheduleReminderSettingsPhone';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    MyScheduleSettingsStep1,
    MyScheduleSettingsStep2,
    MyScheduleReminderSettingsIndex,
    MyScheduleReminderSettingsCalendar,
    MyScheduleReminderSettingsPhone,
  },

  props: {
    settings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentStep: 1,
      numDaysSelected: this.settings.length,
      selectedDays: this.settings,
    };
  },

  watch: {
    numDaysSelected(newValue) {
      // try to keep existing data on schedule edit as much as possible
      if (newValue <= this.selectedDays.length) {
        this.selectedDays = this.settings.slice(0, newValue);
      } else {
        const numDaysToAdd = newValue - this.settings.length;

        this.selectedDays = [
          ...this.settings,
          ...new Array(numDaysToAdd).fill({}),
        ];
      }
    },
  },

  methods: {
    ...mapActions({
      create: 'schedule/create',
      delete: 'schedule/delete',
    }),

    async onSave() {
      await this.create(this.selectedDays);

      this.trackEvent('Weekplanner scheduled', {
        Days: this.selectedDays.length,
      });

      this.currentStep  = 3;
    },

    async onStop() {
      const numDays = this.selectedDays.length;

      await this.delete();

      this.trackEvent('Weekplanner cancelled', {
        Days: numDays,
      });

      this.$emit('close');
    },
  },
};
</script>
