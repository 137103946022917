const mapMyScheduleToICSEvents = (schedule, activePrograms) => schedule.map(event => {
  const oneHour = 60 * 60 * 1000;
  const start = new Date(event.date);
  const end = new Date(event.date).setTime(start.getTime() + oneHour);
  const description = 'Veel sportplezier! 💪';
  let summary = 'Fitchannel workout herinnering';

  if (event.program.id) {
    const program = activePrograms.find(({ id }) => id === event.program.id);

    if (program) {
      summary += `: ${program.title} met ${program.trainer}`;
    }
  }

  return { start, end, summary, description };
});

export default mapMyScheduleToICSEvents;