<template>
  <div class="myScheduleReminderSettingsIndex">
    <base-heading
      type="2"
      modifiers="center"
      class="myScheduleReminderSettingsIndex__title"
    >
      {{ $t('myScheduleReminderSettingsModal.stepIndexTitle') }}
    </base-heading>

    <p class="myScheduleReminderSettingsIndex__body">
      {{ $t('myScheduleReminderSettingsModal.stepIndexBody') }}
    </p>

    <base-button
      modifiers="secondary block fullWidth"
      v-on:click="$emit('calendar')"
    >
      {{ $t('myScheduleReminderSettingsModal.stepIndexCalendarButtonLabel') }}
    </base-button>

    <div class="myScheduleReminderSettingsIndex__separator">
      <span class="myScheduleReminderSettingsIndex__separatorLabel">
        {{ $t('myScheduleReminderSettingsModal.stepIndexAndOr') }}
      </span>
    </div>

    <base-button
      modifiers="secondary block fullWidth"
      v-on:click="$emit('phone')"
    >
      {{ $t('myScheduleReminderSettingsModal.stepIndexSMSButtonLabel') }}

      <font-awesome-icon
        v-if="profile.mobile_number && privacySettings.notification_sms"
        icon="circle-check"
        class="myScheduleReminderSettingsIndex__smsButtonIcon"
      />
    </base-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },

  computed: {
    ...mapState('member', ['profile', 'privacySettings']),
  },

  async created() {
    await this.fetchPrivacySettings();
    await this.fetchProfile();
  },

  methods: {
    ...mapActions('member', ['fetchProfile', 'fetchPrivacySettings']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleReminderSettingsIndex__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.myScheduleReminderSettingsIndex__body {
  margin: 0 0 2rem 0;
  text-align: center;
  color: $color-grey;
}

.myScheduleReminderSettingsIndex__separator {
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(#0a1d33, 0.1);
    z-index: 0;
  }
}

.myScheduleReminderSettingsIndex__separatorLabel {
  @include label;
  margin: 1.5rem 0;
  padding: 0 0.75rem;
  background: $color-white;
  color: $color-grey;
  z-index: 1;
}

.myScheduleReminderSettingsIndex__smsButtonIcon {
  margin: 0 0 0 rem(8px);
  font-size: rem(18px);
  color: $color-mint;
}
</style>