<template>
  <div class="formElementNote">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.formElementNote {
  @include label;
  margin: rem(8px) 0 0 0;
  color: $color-grey;
}
</style>