<template>
  <Field
    v-bind:name="$attrs.id"
    v-slot="{ field }"
    v-bind:class="{
      'formInput': true,
      'formInput--password': isPasswordFieldType,
    }"
    as="div"
  >
    <input
      v-bind="{ ...field, ...$attrs }"
      v-bind:type="fieldType || $attrs.type || 'text'"
      v-bind:class="{
        'formInput__field': true,
        [fieldClass || $attrs.class || '']: true,
      }"
    >

    <span
      v-if="isPasswordFieldType"
      class="formInput__toggle"
      v-on:click="onToggleClick"
    >
      <font-awesome-icon v-bind:icon="['fas', toggleIcon]" />
    </span>
  </Field>
</template>

<script>
import { Field } from 'vee-validate';

export default {
  inheritAttrs: false,

  components: {
    Field,
  },

  data() {
    return {
      isPasswordFieldType: this.$attrs.type === 'password',
      fieldType: this.$attrs.type,
      fieldClass: this.$attrs.class,
    };
  },

  computed: {
    toggleIcon() {
      return this.fieldType === 'password' ? 'eye' : 'eye-slash';
    },
  },

  methods: {
    onToggleClick() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formInput--password {
  position: relative;
}

.formInput__field {
  @include paragraph;
  padding: rem(8px);
  width: 100%;
  background: $color-white;
  border: 1px solid $color-beige--dark;
  border-radius: rem(6px);
  color: $color-text;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  .formInput--password & {
    padding-right: rem(48px);
  }

  &:focus {
    outline-color: $color-blue;
  }
}

.formInput__btn_right {
  padding-right: rem(120px);
  border-radius: rem(20px);
  height: rem(44px);
  background-color: $color-beige;

  &::placeholder {
    color: $color-beige--dark;
  }
}

.formInput__toggle {
  position: absolute;
  top: rem(11px);
  right: rem(16px);
  color: rgba($color-text, 0.5);

  .formInput--password & {
    cursor: pointer;
  }
}
</style>