<template>
  <div class="input-wrapper">
    <label
      v-bind:class="{
        'selectButton': true,
        'selectButton--error': error,
        [buttonType]: true,
      }"
    >
      <select
        v-bind="{ ...$attrs }"
        v-model="currentOption"
        v-bind:disabled="disabled"
        v-on:change="$emit('update:modelValue', $event.target.value)"
      >
        <option
          v-for="(option, index) in options"
          v-bind:key="index"
          v-bind:value="option.value"
          v-bind:selected="currentOption === option.value"
          v-bind:disabled="option.disabled"
        >
          {{ loading ? null : option.name }}
        </option>
      </select>

      <font-awesome-icon :icon="['fas', 'caret-down']" />

      <svg v-if="loading" class="spinner" viewBox="0 0 30 30"><use xlink:href="./../assets/icons.svg#spinner" /></svg>
    </label>

    <p
      v-if="error"
      class="input-error-message"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      required: true,
    },

    selected: {
      type: [String, Number, Date, Object],
      default: 1,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: null,
    },

    modelValue: {
      type: [String, Number, Date, Object, null],
      default: null,
    },
  },

  data() {
    return {
      currentOption: null,
      mounted: false,
    };
  },

  computed: {
    buttonType() {
      return `selectButton--${this.type}`;
    }
  },

  watch: {
    selected() {
      this.currentOption = this.selected;
    },
  },

  mounted() {
    this.currentOption = this.selected;
  }
};
</script>

<style lang="scss">
@import "@/scss/lib";

.selectButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .svg-inline--fa {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-text;
    pointer-events: none;
  }

  select {
    @include paragraph;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 32px 0 12px;
    width: 100%;
    min-height: 42px;
    border-radius: 5px;
    background: $color-beige;
    color: $color-text;
    border: none;
    text-decoration: none;
    transition: all 0.2s;
    outline: none;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
  }

  &--secondary {
    select {
      background: $color-beige;
      color: $color-text;

      @include desktop {
        &:hover {
          background: $color-beige--dark;
        }
      }
    }

    .svg-inline--fa {
      color: $color-text;
    }
  }

  .spinner {
    position: absolute;
    left: calc(50% - 8px);
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
    height: 24px;
    width: 24px;
    color: $color-text;
    animation: selectbox-spin-animation 2s linear infinite;
  }

  @keyframes selectbox-spin-animation {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

.selectButton--error {
  select {
    border: 1px solid #ea4040;
  }
}
</style>
