import moment from 'moment';

const convertDate = date => {
  return moment(date)
    .format('YYYYMMDDTHHmm00');
}

const createEvent = ({ start, end, summary, description }) => {
  return `BEGIN:VEVENT
    UID:${convertDate(start)}
    DTSTAMP:${convertDate(new Date())}
    DTSTART:${convertDate(start)}
    DTEND:${convertDate(end)}
    SUMMARY:${summary}
    DESCRIPTION:${description}
    BEGIN:VALARM
    TRIGGER:-PT30M
    ACTION:DISPLAY
    DESCRIPTION:${summary}
    END:VALARM
    END:VEVENT`.replace(/  +/g, '');
}

const createCalendar = events => {
  const eventsString = events.reduce((acc, event, index, array) => {
    const lineEnd = index !== array.length - 1 ? '\n' : '';

    return `${acc}${createEvent(event)}${lineEnd}`;
  }, '');

  return `BEGIN:VCALENDAR
    CALSCALE:GREGORIAN
    METHOD:PUBLISH
    PRODID:Weekplanner
    VERSION:2.0
    ${eventsString}
    END:VCALENDAR`.replace(/  +/g, '');
};

const createMyScheduleICSFile = (fileName, events) => {
  const fileContent = createCalendar(events);
  const fileOptions = { type: 'text/calendar' };
  const file = new File([fileContent], fileName, fileOptions);

  return file;
};

export default createMyScheduleICSFile;