<template>
  <div class="myScheduleReminderSettingsPhone">
    <base-heading
      type="2"
      modifiers="center"
      class="myScheduleReminderSettingsPhone__title"
    >
      {{ $t('myScheduleReminderSettingsModal.stepSMSTitle') }}
    </base-heading>

    <p class="myScheduleReminderSettingsPhone__body">
      {{ $t('myScheduleReminderSettingsModal.stepSMSBody') }}
    </p>

    <div class="myScheduleReminderSettingsPhone__notification">
      <label
        for="smsNotification"
        class="myScheduleReminderSettingsPhone__notificationBody"
      >
        {{ $t('myScheduleReminderSettingsModal.stepSMSToggleLabel') }}
      </label>

      <checkbox-toggle
        v-model="notification_sms"
        id="smsNotification"
        class="myScheduleReminderSettingsPhone__notificationToggle"
      />
    </div>

    <Form
      v-bind:initial-values="formData"
      v-slot="{ handleSubmit, isSubmitting }"
      as="div"
    >
      <base-form v-on:submit="handleSubmit($event, onSubmit)">
        <base-form-element>
          <base-form-label for-id="mobile_number">
            {{ $t('myScheduleReminderSettingsModal.stepSMSPhonenumberLabel') }}
          </base-form-label>

          <base-form-input
            id="mobile_number"
            class="myScheduleReminderSettingsPhone__formInput"
          />

          <base-form-note>
            {{ $t('myScheduleReminderSettingsModal.stepSMSPhonenumberNote') }}
          </base-form-note>
        </base-form-element>

        <base-button
          modifiers="primary block fullWidth"
          v-bind:show-spinner="isSubmitting"
          v-bind:disabled="isSubmitting"
          class="myScheduleReminderSettingsPhone__formSubmit"
        >
          {{ $t('myScheduleReminderSettingsModal.stepSMSSaveButtonLabel') }}
        </base-button>
      </base-form>
    </Form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Form } from 'vee-validate';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import CheckboxToggle from '@/components/CheckboxToggle';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseFormNote from '@/components/forms/BaseFormNote';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    Form,
    BaseHeading,
    BaseButton,
    CheckboxToggle,
    BaseForm,
    BaseFormElement,
    BaseFormLabel,
    BaseFormInput,
    BaseFormNote,
  },

  data() {
    return {
      formData: {},
    };
  },

  computed: {
    ...mapState('member', ['profile', 'privacySettings']),

    notification_sms: {
      get() {
        return this.privacySettings.notification_sms;
      },
      set(value) {
        this.updatePrivacySettings({ notification_sms: value })
          .then(this.showSaveNotification);
      },
    },
  },

  async created() {
    await this.fetchPrivacySettings();
    await this.fetchProfile();

    this.formData.mobile_number = this.profile.mobile_number;
  },

  methods: {
    ...mapActions('member', [
      'fetchProfile',
      'updatePhoneNumber',
      'fetchPrivacySettings',
      'updatePrivacySettings',
    ]),

    async onSubmit(values) {
      await this.updatePhoneNumber(values);

      if (this.privacySettings.notification_sms) {
        this.trackEvent('Weekplanner reminders enabled');
      }

      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.myScheduleReminderSettingsPhone__title {
  @include desktop {
    margin: 0 0 rem(40px) 0;
  }
}

.myScheduleReminderSettingsPhone__body {
  margin: 0 0 rem(32px) 0;
  text-align: center;
  color: $color-grey;
}

.myScheduleReminderSettingsPhone__notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px 0;
  padding: 22px 0;
  border-top: 1px solid rgba(#0a1d33, 0.1);
  border-bottom: 1px solid rgba(#0a1d33, 0.1);
}

.myScheduleReminderSettingsPhone__notificationBody {
  color: #222;
  cursor: pointer;
}

.myScheduleReminderSettingsPhone__formInput {
  max-width: 240px;
}

.myScheduleReminderSettingsPhone__formSubmit {
  margin: rem(64px) 0 0 0;
}
</style>