import moment from "moment";

/**
 * Generates an array of time slots
 * @param {Number} every
 * @param {String} unit
 * @returns {Array}
 */
const generateTimeSlots = (every, unit = 'minutes') => {
  const timeSlots = [];
  const startTime = moment()
    .utc()
    .set({ hour: 0, minute: 0 });
  const endTime = moment()
    .utc()
    .set({ hour: 23, minute: 59 });

  // eslint-disable-next-line
  while (startTime <= endTime) {
    const timeSlot = new moment(startTime).format('HH:mm');

    timeSlots.push(timeSlot);
    startTime.add(every, unit);
  }

  return timeSlots;
};

export default generateTimeSlots;
